.form>h4 {
    margin-bottom: 20px;
}

.form>div,
#treeview {
    display: inline-block;
    vertical-align: top;
}


.treeview .dx-item-content .dx-treeview-item-content {
    padding-left: 14px;  
}

.treeview .dx-item-content {
    padding-left: 14px;
}
.treeview .dx-state-hover {
    background-color:unset!important;
}
.treeview .dx-checkbox-checked .dx-checkbox-icon{
background-color: #F6F6F6!important;
}
.treeview .dx-checkbox-indeterminate .dx-checkbox-icon{
    background-color: #F6F6F6!important;
    border: 2px solid rgba(0,0,0,.54);
}
.treeview .dx-checkbox-indeterminate .dx-checkbox-icon::after{
    content: unset!important;
}
.treeview .dx-checkbox-indeterminate .dx-checkbox-icon:before{
    content: unset!important;   
}

.dx-searchbox.dx-editor-filled .dx-placeholder::before, .dx-searchbox.dx-editor-filled .dx-texteditor-input, .dx-searchbox.dx-editor-outlined .dx-placeholder::before, .dx-searchbox.dx-editor-outlined .dx-texteditor-input {
    padding-left: 32.5px !important;
}

.dx-datagrid .dx-datagrid-table .dx-header-row>td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center !important;
}

.uu-dai-list{
    margin-top: 0;
}

.uu-dai-list .dx-list-item {
    border-bottom: 1px solid #e0e0e0;
}