.p-is-active{
    background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
  }
  .p-prim-col{
    /* background: linear-gradient(to bottom, #4fc5fa 0%, #0f75f5 100%); */
    background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
    background-size: 100%;
    border:0;
    box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgba(0,0,0,.3);
    color: white!important;
  }
  .p-btn.p-prim-col:active{
    background-color: #3caa62;
  }
.p-btn-sm > i{
    padding-right: 5px;
}

.margin-left-10{
  margin-left: 10px;
}
/* .p-btn{
  height: 33px;
} */