body {
    background-color: #EEF5F7 !important;
}

.form_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_container :global(.dx-texteditor.dx-editor-underlined::after) {
    border-bottom: 1px solid #EEF5F7;
    /* rgba(0,0,0,0.07); */
}

.form {
    max-width: 650px;
    /* border: 1px solid #ececec; */
    margin-top: 20px;
    width: 100%;
}

.form:global(.screen-x-small) {
    width: 100%;
    border: 0;
}

.cover_img_container {
    padding: 1rem;
    padding-bottom: 0px;
    border-radius: 16px;
    height: 200px;
    margin: 1rem;
    margin-top: 0px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.cover_img_container img {
    width: 100%;
    height: auto;
    border-radius: 16px;
}

.form_area {
    padding: 1rem;
    padding-top: 0px;
}

.caption {
    font-size: 24px;
    font-weight: 500;
}

.form_group {
    margin-bottom: 5px;
    margin-top: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border-radius: 10px;
    padding: 24px;
    background-color: #fff;
}

.form_label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.form_help_text {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.5);
}

.form_actions {
    padding-top: 10px;
    padding-bottom: 20px;
}

.result {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.result img {
    width: 128px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.result p {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

.result b {
    color: #03a9f4;
}

.thank_caption {
    font-weight: bold;
    font-size: 15px !important;
    margin-bottom: 0px !important;
}

.description {
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px !important;
    line-height: 20px !important;
}
.top_description{
    margin-top: 16px;
}
.bottom_description{
    margin-top: 16px;
}
.btnSubmit{
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #03a9f4;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    letter-spacing: 0.8px;
    cursor: pointer;
}