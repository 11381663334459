.container {
    width: 100%;
}

.form_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
    max-width: 450px;
    border: 1px solid #ececec;
    /* margin-top: 20px; */
}

.form:global(.screen-x-small) {
    width: 100%;
    border: 0;
}

.cover {
    width: 100%;
    height: auto;
}

.caption {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.thank_img {
    height: 100px;
}

.result {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.status_caption {
    font-size: 14px;
    font-weight: bold;
}

.content {
    padding: 1rem;
    text-align: center;
}