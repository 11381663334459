.custom-file-input{
    width: 150px;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select file upload';
    display: inline-block;
    
    background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
    border:0;
    box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgb(0 0 0 / 30%);
    border-radius: .3rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: white!important;
    text-transform: unset!important;
    font-family: -apple-system, "Inter", sans-serif!important;
    font-size: 1rem;
    padding: .3rem 1.1rem;
  }
  