.btn {
    display: inline-block;
    font-weight: 500;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    /* padding: .375rem .75rem; */
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 4px;
    /* font-size: 13px; */
    border-radius: 6px;
    color: #333230;
    background-color: #fff;
    border: 0;
    /* min-height: 26px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    font-weight: bold;
    margin-right: 1px;
    font-size: 12px;
}
button:not(.hasIcon){
    padding-left: 12px;
    padding-right: 12px;
    min-width: 100px;
}

.btn_disabled {
    opacity: 0.6;
    pointer-events: none;
}

.icon {
    margin-left: 5px;
    margin-right: 5px;
}

.btn_primary {
    color: #fff;
    /* background: linear-gradient(to bottom, #4fc5fa 0%, #0f75f5 100%); */
    background: linear-gradient(180deg, #32cd32 0, #3caa62);
    border: 0;
    /* box-shadow:  0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgb(0 0 0 / 30%); */
}

.btn_primary:focus {
    /* background: #0f75f5;
    box-shadow: 0 0 0 2px #64baff; */
    box-shadow: 0 0 0 2px #48d148;

}

.btn_success {
    color: #fff;
    background: linear-gradient(180deg, #32cd32 0, #3caa62);
    box-shadow: 0 1px 1px rgba(50, 205, 50, 0.12), 0 2px 3px -2px rgba(50, 205, 50, 0.32);
    border: 0;
}

.btn_success:focus {
    box-shadow: 0 0 0 2px #48d148;
}

.btn_danger {
    color: #fff;
    background: linear-gradient(to bottom, #f32727 0, #c2242c);
}

.btn_danger:focus {
    box-shadow: 0 0 0 2px #f77070;
}


.btn_info {
    color: #fff;
    background: linear-gradient(to bottom, #27caf3 0, #24c2ba);
}

.btn_info:focus {
    box-shadow: 0 0 0 2px #70f3f7;
}

.btn_none {
    display: inline-block;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: 2px;
    border-radius: 6px;
    font-weight: bold;
    margin-right: 1px;
    border: 0;
    font-size: 11px;
}

.btn_link {
    font-weight: 600;
    color: #4a75c7;
}

.btn_link:hover {
    color: #0d6efd;
    text-decoration: underline;
}